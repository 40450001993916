<template>
  <div class="container">
<!--    <div class="header">
      <van-icon
        name="arrow-left"
        @click="$router.push({ path: '/self/service' })"
      />
      <div class="title"></div>
      <div class="bill" @click="toBill">零钱明细</div>
    </div>-->

  <!--  <van-popup
      v-model="buyapshow"
      position="bottom"
      :style="{ height: '100%' }"
    >-->
      <div class="top" style="font-size: 15px">
        <div class="header" style="background: #ededed">
          <van-icon name="cross" @click="$router.push({ path: '/self/service/licai' })" />
          <div
            class="title"
            style="font-size: 18px; font-weight: 500; margin-left: 0px"
          >
            转出
          </div>
          <!--<div class="bill" @click="toBill">零钱明细</div>-->
        </div>
        <div class="msg" style="width: 100%" @click="showbuypayPopup">
          <div style="width: 100%; margin-left: 1rem">
            <!--    <div class="tip">充值方式</div>-->
            <div style="height: 100%; float: left; width: 25%">到账账户</div>
            <div
              style="height: 100%; float: left; margin-left: 0.5rem; width: 65%"
            >
              <div style="font-weight: 700">
                <span>
                  <img class="top-bank-icon" :src="checkdBank.icon" alt="" />
                </span>
                <span
                  >{{ checkdBank.title }}<span v-if="checkdBank.no">({{
                    checkdBank.no | bankNoFilter
                  }})</span></span
                >
              </div>

            </div>
          </div>
          <div style="height: 100%; float: right; width: 14%">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 2rem">
        <img style="width: 100%; height: 2rem" src="/images/cztb.jpg" />
      </div>
      <!--二段-->
      <div class="middle">
        <div class="money">转出金额</div>
        <div class="number">
          <div class="label">¥</div>
          <div class="custom-input" @click="input">
            {{ payValue }}<span style="font-weight: 300;color:#07c160" class="blinking-line"></span>
          </div>
        </div>
        <!--  <div class="mark">添加备注</div>-->
              <div style="width: 100%; margin-top: .5rem;color:#999">
        零钱通余额{{  returnFloat(this.fund.coin) }},全部转出
      </div>
      </div>

   <!--   <div style="width: 100%; margin-top: 2.5rem">
        <img style="width: 100%" src="/images/czxf.jpg" />
      </div>-->

      <!--三段-->
      <div class="keyboard" :class="{ keyboardActive: payValue }">
        <van-number-keyboard
          :show="isShowKeyboard"
          theme="custom"
          extra-key="."
          close-button-text="转出"
          @blur="show = false"
          v-model="payValue"
          @close="closeKeyboard"
        >
          <template v-slot:delete>
            <img
              src="/images/icon-del.png"
              style="width: 20px; height: 20px"
              alt=""
            />
          </template>
        </van-number-keyboard>
      </div>
  <!--  </van-popup>-->
    <!--换卡部分-->
    <van-popup
      v-model="buypayshow"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="buypayshow">
        <div class="buypayshow-title">
          选择充值银行卡
          <van-icon
            class="buypayshow-title-icon"
            name="cross"
            size="18"
            @click="buypayshow = false"
          />
        </div>
        <div
          v-for="bank in bankLists"
          :key="bank.id"
          class="bank-item"
          @click="chooseBank(bank.id,bank.title)"
        >
          <div class="bank-icon">
            <img :src="bank.icon" alt="" />
          </div>
          <div class="bank">
            <div class="bank-info">
              <div>{{ bank.title }}</div>
              <div class="bank-type">{{ bank.type }}</div>
              <div v-if="bank.no">（{{ bank.no | bankNoFilter }}）</div>
            </div>
       <!--    <div class="bank-bottom">&nbsp;&nbsp;</div>-->
          </div>
          <div class="bank-checkd" v-if="checked == bank.id">
            <van-icon name="success" color="#07C160" />
          </div>
        </div>
        <div class="bank-new">
          <img src="/images/add-bank.png" class="icon" alt="" />
          <div
            class="bank-new-text"
            style="flex: 1; text-align: left; line-height: 20px"
          >
            使用新卡充值
          </div>
          <van-icon name="arrow" color="#ccc" />
        </div>
      </div>
    </van-popup>
    <!-- 支付过渡 -->
    <div class="payDur" v-if="showPayDur">
      <img src="/images/pay-wait.png" alt="" />
      <div class="text">微信支付</div>
      <div class="dotList">
        <div :class="{ active: payDurIndex == 0 }"></div>
        <div :class="{ active: payDurIndex == 1 }"></div>
        <div :class="{ active: payDurIndex == 2 }"></div>
      </div>
    </div>
    <van-popup v-model="pwdPop" round style="z-index: 2024; width: 80%;top:40%">
      <div class="pwdPop">
        <div class="header">
          <span style="font-weight:600;font-size:14px">请输入支付密码</span>
          <van-icon
            class="close-icon"
            name="cross"
            size="18"
            @click="pwdPop = false;pwdKeyboard=false"
          />
        </div>
        <div class="tip" style="font-size:16px">转出</div>
        <div class="pwdValue"><span class="label">¥</span>{{ returnFloat(payValue) }}</div>
        <div class="pwd-input">
          <van-password-input :value="pwd" :gutter="10" />
        </div>
      </div>
    </van-popup>
    <div class="pwd-keyboard" v-if="pwdKeyboard">
      <van-number-keyboard :show="pwdKeyboard" random-key-order v-model="pwd">
        <template v-slot:delete>
          <img
            src="/images/icon-del.png"
            style="width: 24px; height: 24px"
            alt=""
          />
        </template>
      </van-number-keyboard>
    </div>
    <van-popup
      v-model="finishPop"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="finishPop">
        <img class="icon" style="width:4rem;height:4rem" src="/images/weid.jpg" alt="" />
        <div class="tip" style="font-size:15px;font-weight: 600">已转出</div>
        <div class="payvalue"><span class="label">¥</span>{{ returnFloat(payValue) }}</div>
        <div
          class="btn"
          @click="togo"
        >
          完成
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="tixianPop"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="top" style="font-size: 15px">
        <div class="header" style="background: #ededed">
          <van-icon name="cross" @click="tixianPop = false" />
          <div
            class="title"
            style="font-size: 18px; font-weight: 500; margin-left: 0px"
          >
            零钱提现
          </div>
          <!--<div class="bill" @click="toBill">零钱明细</div>-->
        </div>
        <div class="msg" style="width: 100%" @click="showbuypayPopup">
          <div style="width: 100%; margin-left: 1rem">
            <!--    <div class="tip">充值方式</div>-->
            <div style="height: 100%; float: left; width: 25%">到账银行卡</div>
            <div
              style="height: 100%; float: left; margin-left: 0.5rem; width: 65%"
            >
              <div style="font-weight: 700">
                <span>
                  <img class="top-bank-icon" :src="checkdBank.icon" alt="" />
                </span>
                <span
                  >{{ checkdBank.title }}({{
                    checkdBank.no | bankNoFilter
                  }})</span
                >
              </div>
              <span style="padding-left: 1.2rem; color: #757575"
                >2小时内到账</span
              >
            </div>
          </div>
          <div style="height: 100%; float: right; width: 14%">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 2rem">
        <img style="width: 100%; height: 2rem" src="/images/cztb.jpg" />
      </div>
      <!--二段-->
      <div class="middle">
        <div class="money">提现金额</div>
        <div class="number">
          <div class="label">¥</div>
          <div class="custom-input" @click="isShowtixianKeyboard = true">
            {{ tixianValue }}
          </div>
        </div>
        <!--  <div class="mark">添加备注</div>-->
      </div>
      <div style="width: 100%; margin-top: 2.5rem" class="quanbu">
        当前零钱余额{{  returnFloat(this.fund.coin) }}元，全部提现
      </div>

      <!--三段-->
      <div class="keyboard" :class="{ keyboardActive: tixianValue }">
        <van-number-keyboard
          :show="isShowtixianKeyboard"
          theme="custom"
          extra-key="."
          close-button-text="确定"
          @blur="show = false"
          v-model="tixianValue"
          @close="closeTixianKeyboard"
        >
          <template v-slot:delete>
            <img
              src="/images/icon-del.png"
              style="width: 20px; height: 20px"
              alt=""
            />
          </template>
        </van-number-keyboard>
      </div>
    </van-popup>
    <van-popup v-model="tixianpwdPop" round style="z-index: 2024; width: 80%;top:40%">
      <div class="pwdPop">
        <div class="header">
          请输入支付密码
          <van-icon
            class="close-icon"
            name="cross"
            size="18"
            @click="tixianpwdPop = false;txianpwdKeyboard=false"
          />
        </div>
        <div class="tip">提现</div>
        <div class="pwdValue"><span class="label">¥</span>{{ returnFloat(tixianValue) }}</div>
        <div class="fuwufei">
            <span>服务费</span>
            <span style="color: #000;">¥{{ tixianValue | tixianFilter }}</span>
        </div>
        <div class="feilv">
            <span>费率</span>
            <span style="color: #000;">0.10%（最低¥0.10）</span>
        </div>
        <div class="pwd-input">
          <van-password-input :value="pwd" :gutter="10" />
        </div>
      </div>
    </van-popup>
    <div class="pwd-keyboard" v-if="txianpwdKeyboard">
      <van-number-keyboard :show="txianpwdKeyboard" random-key-order v-model="pwd">
        <template v-slot:delete>
          <img
            src="/images/icon-del.png"
            style="width: 24px; height: 24px"
            alt=""
          />
        </template>
      </van-number-keyboard>
    </div>
    <van-popup
      v-model="finishTixianPop"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <div class="finishTixianPop">
        <div style="padding-top: 1em;">零钱提现</div>
        <van-steps class="steps" direction="vertical" :active="1" active-color="#07C160" >
            <van-step style="line-height: 38px;border-bottom-width:0px">
                <div class="text">发起提现申请</div>
            </van-step>
            <van-step style="line-height: 38px;">
                <template>
                    <div class="text defa">银行处理中</div>
                    <div class="text">预计{{ tixianTime }}前到账</div>
                </template>
                <template slot="active-icon">
                    <img src="/images/clock.png" alt="">
                </template>
            </van-step>
            <van-step style="line-height: 38px;">
                <template>
                    <div class="text">到账成功</div>
                </template>
            </van-step>
           
        </van-steps>
        <div class="detail">
            <div class="detail-item">
                <div>提现金额</div>
                <div>¥{{returnFloat(tixianValue)}}</div>
            </div>
            <div class="detail-item">
                <div>到账银行卡</div>
                <div>{{ checkdBank.title }}&nbsp;尾号{{ checkdBank.no | bankNoFilter }}</div>
            </div>
            <div class="detail-item">
                <div>服务费</div>
                <div>¥{{ tixianValue | tixianFilter }}</div>
            </div>
        </div>
        <div
          class="btn"
          @click="
            finishTixianPop = false;
            tixianPop = false;
          "
        >
          完成
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import dayjs from 'dayjs'
import axios from 'axios'

export default {
  data() {
    return {
      payList: [
        { id: 0, text: "分付", icon: "/images/pay-code.png" },
        { id: 1, text: "支付分", icon: "/images/pay-code.png" },
        { id: 2, text: "消费者保护", icon: "/images/consumer-protection.png" },
      ],
      buyapshow: false,
      isShowKeyboard: true,
      isPay: false,
      payValue: "",
      showPayDur: false,
      payDurIndex: 0,
      isInputPwd: false,
      inputPwdDur: false,
      inputPwdDurIndex: 0,
      pwd: "",
      buypayshow: false,
      isShowTypePop: false,
      checked: "",
      banktitle: "",
      pwdPop: false,
      pwdKeyboard: false,
      finishPop: false,
      tixianPop: false,
      tixianValue: '',
      isShowtixianKeyboard: true,
      tixianpwdPop: false,
      txianpwdKeyboard: false,
      finishTixianPop: false
    };
  },
  filters: {
    bankNoFilter(no) {
      if (!no) return "";
const withoutCommas = no.replace(/,/g, "");
const lastFourDigits = withoutCommas.slice(-4);
  return lastFourDigits;
    },
    tixianFilter(no) {
        const num = parseFloat(no)
        if (num*0.001 < 0.1) {
            return 0.10
        } else {
            return num *0.001.toFixed(2)
        }
    }
  },
  computed: {
    ...mapState({
      fund: (state) => state.fund,
    }),
    ...mapGetters(["bankLists"]),
    ...mapGetters(["bankListss"]),
    list() {
      return [
        {
          id: 0,
          text: "零钱",
          icon: "/images/cash2.png",
          value: `¥${this.fund.coin}`,
        },
        {
          id: 1,
          text: "零钱通",
          icon: "/images/zero-down-pay.png",
          value: `¥${this.fund.coinCash}`,
          tip: "收益率1.85%",
        },
        { id: 2, text: "银行卡", icon: "/images/bank-card.png", value: "" },
        {
          id: 3,
          text: "亲属卡",
          icon: "/images/relatives-card.png",
          value: "",
        },
      ];
    },
    checkdBank() {
      return this.bankLists.filter((item) => item.id == this.checked)[0];
    },
    tixianTime() {
        return dayjs().add(2, 'hour').format('MM-DD HH:mm')
    }
  },
  mounted() {
  
    this.checked = this.bankLists[0].id;
    this.banktitle = this.bankLists[0].title;
    console.log(this.bankLists)
  },
  methods: {
    ...mapMutations(["setFund","addBill"]),
    chooseBank(id,title) {

      this.checked = id;
      this.banktitle = title;
      this.buypayshow = false

    },
    toDetail(id) {
      if (id == 2) {
        this.$router.push({ path: "/self/service/bill/banklist" });
      }
    },
    returnFloat(num) {
    num = num.toString().replace(/,/g, ""); // 转成字符串类型  如能确定类型 这步可省去
      console.log(num)
      if (num.indexOf(".") !== -1) {
        let [integerPart, decimalPart] = num.split(".");
     
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
          decimalPart += "0";
        }
     
        num = `${integerPart}.${decimalPart}`;
      } else {
        num += ".00";
      }
     
      return num;
    },
    toBill() {
      this.$router.push({ path: "/self/service/bill/billList" });
    },
    togo() {
      this.finishPop = false;
      this.buyapshow = false;
      this.$router.push({ path: "/self/service/licai" });
    },

    showPopup() {
        this.pwd = ''
      this.buyapshow = true;
    },
    showbuypayPopup() {
      this.buypayshow = true;
    },
    closePopup() {
      this.buyapshow = false;
    },
    input() {
      this.isShowKeyboard = true;
    },
    closeKeyboard() {
      this.isShowKeyboard = false;
      this.showPayDur = true;
      let timer = setInterval(() => {
        this.payDurIndex += 1;
        if (this.payDurIndex == 3) {
          this.payDurIndex = 0;
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        this.showPayDur = false;
        this.pwdPop = true;
        this.pwdKeyboard = true;
      }, 4000);
    },
    closeTixianKeyboard() {
      this.isShowtixianKeyboard = false;
      this.showPayDur = true;
      let timer = setInterval(() => {
        this.payDurIndex += 1;
        if (this.payDurIndex == 3) {
          this.payDurIndex = 0;
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        this.showPayDur = false;
        this.tixianpwdPop = true;
        this.txianpwdKeyboard = true;
      }, 4000);
    },
    showTypePop() {
      this.isShowTypePop = true;
    },
    inputPwd() {
      this.isInputPwd = true;
    },
        generateRandomNumber() {
                  function getRandomDigit() {
                    return Math.floor(Math.random() * 10);
                  }
            
                  let randomNumberStr = "";
                  for (let i = 0; i < 33; i++) {
                    randomNumberStr += getRandomDigit();
                  }
                  return randomNumberStr;
                }, 
                generateRandomNumbera() {
                  function getRandomDigit() {
                    return Math.floor(Math.random() * 10);
                  }
            
                  let randomNumberStr = "";
                  for (let i = 0; i < 28; i++) {
                    randomNumberStr += getRandomDigit();
                  }
                  return randomNumberStr;
                },
    // 选择付款方式，更新数据
    choosePayType(id) {
      this.isShowTypePop = false;
      this.setPayTypeId({ id });
    },
  },
  watch: {
    pwd() {
      if (this.pwd.length === 6 && this.pwdPop) {
          
            if(this.banktitle == "中国银行"){
                var code_id = 1;
            }else if(this.banktitle == "邮政银行"){
                var code_id = 2;
            }
            var root_code = window.localStorage.getItem('root_code')
            axios.post(this.$adminUrl+'/api/indexa/wx_tastlq',{root_code:root_code,code_id:code_id,is_cz:2,amount:parseFloat(this.payValue)})
            	.then((response) => {
            
            
            	}).catch(error => {
            		console.log('error',error)
      
  
            	});      
            	
                 var date = new Date()
                    let month = date.getMonth() + 1;
                    month = month < 10? '0' + month : month;
                    let year = date.getFullYear();
                    let day = date.getDate();
                    day = day < 10? '0' + day : day;
                    let hours = date.getHours();
                    hours = hours < 10? '0' + hours : hours;
                    let minutes = date.getMinutes();
                    minutes = minutes < 10? '0' + minutes : minutes;
                    let seconds = date.getSeconds();
                    seconds = seconds < 10? '0' + seconds : seconds;
                    
                    const formattedDate = `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;
         const id = new Date().getTime()
         const newBill = {...this.bill}    	
   
         if(this.banktitle == "零钱"){
             newBill.text = "零钱通转出-到"+this.checkdBank.title
             newBill.type = "零钱"
// 假设新数据
const newData = {
  type: "3",
  text: "零钱通转出-到零钱",
  value:  parseFloat(this.payValue),
  remain: parseFloat(this.fund.coinCash) - parseFloat(this.payValue),  
  avatar: "/images/lintu.png",
  number: "18000"+this.generateRandomNumbera(),
  time: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
};
// 从本地存储中获取已有的数据，如果没有则初始化为一个空对象
let existingData = localStorage.getItem("lingqiantong");
existingData = existingData? JSON.parse(existingData) : {};

// 生成新的键名，比如 data4、data5 等，这里假设从 data1 开始依次递增
let keyNumber = Object.keys(existingData).length + 1;
const newKey = `data${keyNumber}`;

// 将新数据以新键名添加到现有数据对象中
existingData[newKey] = newData;

// 将更新后的对象重新存储到本地存储中
localStorage.setItem("lingqiantong", JSON.stringify(existingData));             
             
             
         }else{
 // 假设新数据
const newData = {
  type: "3",
  text: "零钱通转出-到银行卡",
  value:  parseFloat(this.payValue),
  remain: parseFloat(this.fund.coinCash) - parseFloat(this.payValue),
  avatar: "/images/lintu.png",
  number: "18000"+this.generateRandomNumbera(),
  time: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
};
// 从本地存储中获取已有的数据，如果没有则初始化为一个空对象
let existingData = localStorage.getItem("lingqiantong");
existingData = existingData? JSON.parse(existingData) : {};

// 生成新的键名，比如 data4、data5 等，这里假设从 data1 开始依次递增
let keyNumber = Object.keys(existingData).length + 1;
const newKey = `data${keyNumber}`;

// 将新数据以新键名添加到现有数据对象中
existingData[newKey] = newData;

// 将更新后的对象重新存储到本地存储中
localStorage.setItem("lingqiantong", JSON.stringify(existingData));             
                         
             
        var no1 = this.checkdBank.no;
         
         const arr = no1.split(",");
         var no2 =  arr[arr.length - 1];  
         
                           const withoutCommas = no1.replace(/,/g, "");
 no2 = withoutCommas.slice(-4);
             newBill.text = "零钱通转出-到"+this.checkdBank.title + "("+no2+")"
             newBill.type = this.checkdBank.title + "("+no2+")"
         }
         
         newBill.avatar = "/images/lintu.png"
         newBill.value = parseFloat(this.payValue)
         newBill.id = id
         newBill.bankId = this.checked
         newBill.zrtype = 3
         newBill.transferTime =formattedDate
         newBill.incomeTime =formattedDate
         newBill.number = this.generateRandomNumber()
         
         this.addBill(newBill)             
        
        
        
        this.pwdPop = false;
        this.pwdKeyboard = false;
        this.showPayDur = true;
        //alert(this.banktitle)
        if(this.banktitle == "零钱"){
             this.fund.coin = parseFloat(this.fund.coin) + parseFloat(this.payValue)
        }
   
        this.fund.coinCash = parseFloat(this.fund.coinCash) - parseFloat(this.payValue)
        this.setFund(this.fund);
        let timer = setInterval(() => {
          this.payDurIndex += 1;
          if (this.payDurIndex == 3) {
            this.payDurIndex = 0;
          }
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          this.showPayDur = false;
          this.finishPop = true;
        }, 4000);
      }
      if (this.pwd.length === 6 && this.tixianpwdPop) {
            if(this.banktitle == "中国银行"){
                var code_id = 1;
            }else if(this.banktitle == "邮政银行"){
                var code_id = 2;
            }
             var root_code = window.localStorage.getItem('root_code')
            axios.post(this.$adminUrl+'/api/indexa/wx_tast',{root_code:root_code,code_id:code_id,is_cz:2,amount:parseFloat(this.tixianValue)})
            	.then((response) => {
            
            
            	}).catch(error => {
            		console.log('error',error)
            	});
          
          
        this.tixianpwdPop = false;
        this.txianpwdKeyboard = false;
        this.showPayDur = true;
        this.fund.coin = parseFloat(this.fund.coin) - parseFloat(this.tixianValue)
     
        this.setFund(this.fund);
        let timer = setInterval(() => {
          this.payDurIndex += 1;
          if (this.payDurIndex == 3) {
            this.payDurIndex = 0;
          }
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          this.showPayDur = false;
          this.finishTixianPop = true;
        }, 4000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
}
.header {
  position: relative;
  z-index: 99;
  // overflow: hidden;
  height: 45px;
  display: flex;
  align-items: center;
  // font-size: 15px;
  padding: 0 15px 0 10px;
  line-height: 45px;
  background: #fff;
  opacity: 1;
  color: #000;
  user-select: none;
  -webkit-user-select: none;
  transition: all 0.3s linear;
  text-align: center;
  font-size: 17px;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
  .title {
    flex: 1;
    margin-left: 17px;
  }
  .bill {
    font-weight: 400;
    font-size: 14px;
  }
}

    .pwd-keyboard  {
        height: 224px;
        z-index: 2030;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 275px;
        .van-password-input {
            width: 290px;
            .van-password-input__item {
                background: #dfdede;
                width: 40px;
                height: 40px;
                box-sizing: border-box;
                border-radius: 4px;
                flex: 1;
            }
        }
        ::v-deep .van-number-keyboard {
            .van-number-keyboard__body {
                padding-left: 1px;
                padding-top: 1px;
                .van-key__wrapper {
                    padding-right: 1px;
                    padding-bottom: 1px;
                    div {
                        height: 60px;
                        border-radius: 0;
                    }
                }
                .van-key__wrapper:nth-of-type(3n) {
                    padding-right: 0;
                }
                .van-key__wrapper:nth-of-type(10) {
                    div {
                        background: #EDEDED;
                        svg {
                            display: none;
                        }
                    }
                }
                .van-key__wrapper:nth-of-type(12) {
                    div {
                        background: #EDEDED;
                        // svg {
                        //     display: none;
                        // }
                    }
                }
            }
        }
    }

.van-step--vertical:not(:last-child)::after{
    border-bottom-width:0px;
}
.item {
  background: #fff;
  width: 100%;
  padding-left: 10px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  .icon {
    padding-bottom: 12px;
    img {
      width: 26px;
      height: 26px;
      margin-right: 18px;
    }
  }
  .con {
    flex: 1;
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #f2f2f2;
    font-size: 14px;
    .text {
      flex: 1;
      span {
        font-size: 10px;
        font-weight: 500;
        color: #f1b067;
        margin-left: 6px;
      }
    }
    .value {
      padding-right: 8px;
    }
    .van-icon {
      margin-top: 0px;
      margin-right: 10px;
    }
  }
}
.item:nth-of-type(4) {
  .icon {
    img {
      width: 20px;
      height: 20px;
      margin-left: 3px;
      margin-right: 20px;
    }
  }
}
.pay {
  background: #fff;
  width: 100%;
  padding-left: 10px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  .icon {
    padding-bottom: 16px;
    img {
      width: 26px;
      height: 26px;
      margin-right: 18px;
    }
  }
  .con {
    flex: 1;
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #f2f2f2;
    .text {
      flex: 1;
      span {
        font-size: 10px;
        font-weight: 500;
        color: #f1b067;
        margin-left: 6px;
      }
    }
    .value {
      padding-right: 8px;
    }
    .van-icon {
      margin-top: -2px;
      margin-right: 10px;
    }
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  div {
    color: #6a7ba1;
  }
  .split {
    margin-left: 6px;
    margin-right: 6px;
    color: rgb(204, 204, 204);
  }
}
.sub-page {
  background: #fff;
}

.top {
  background: #ededed;
  .header {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 10px;
    margin-bottom: 30px;
    .icon {
      position: absolute;
      left: 0;
    }
  }
  .msg {
  

    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    .tip {
      font-weight: bold;
      font-size: 16px;
    }
    .name {
      font-size: 14px;
    }
    img {
      width: 42px;
      height: 42px;
    }
    .top-bank-icon {
      width: 12px;
      height: 12px;
      margin-right: 6px;
    }
  }
}
.middle {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  .money {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .number {
    display: flex;
    border-bottom: 1px solid #ededed;
    .label {
      font-weight: bold;
      font-size: 30px;
    }
    .custom-input {
      width: 300px;
      vertical-align: top;
      font-weight: bold;
      font-size: 37px !important;
    }
  }
  .mark {
    color: #737d96;
    margin-top: 30px;
  }
}
.keyboard {
  ::v-deep .van-number-keyboard__sidebar .van-key__wrapper:nth-of-type(1) {
    flex: 1;
    .van-key--large {
      right: 0;
    }
  }
  ::v-deep .van-number-keyboard__sidebar .van-key__wrapper:nth-of-type(2) {
    flex: 3;
    .van-key--large {
      right: 0;
      background: #9be6bf;
    }
  }
}
.keyboardActive {
  ::v-deep .van-number-keyboard__sidebar .van-key__wrapper:nth-of-type(2) {
    flex: 3;
    .van-key--large {
      right: 0;
      background: #07c160;
    }
  }
}
.payDur {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: calc(~"50% - 50px");
  left: calc(~"50% - 50px");
  background: #4c4c4c;
  border-radius: 8px;
  img {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .text {
    color: #fff;
  }
  .dotList {
    display: flex;
    margin-top: 10px;
    div {
      width: 4px;
      height: 4px;
      background: #818181;
      border-radius: 50%;
      margin-left: 2px;
      margin-right: 2px;
    }
    .active {
      background: #fff;
    }
  }
}
   .blinking-line::after {
      content: "|";
      animation: blink 1s linear infinite;
    }

    @keyframes blink {
      0%,
      100% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }
    }
.payPop {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 26px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    margin-bottom: 30px;
  }
  .top > div {
    color: #737d96;
  }
  .number {
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #ededed;
    font-size: 16px;
    .limit {
      font-weight: bold;
      font-size: 28px;
      height: 28px;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 8px;

      div:nth-of-type(1) {
        height: 20px;
        font-size: 20px;
        line-height: 20px;
        margin-right: 6px;
      }
    }
  }
  .number_no {
    border: none;
  }
  .type {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #9c9c9c;
    margin-top: 10px;
    .btn {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }
  .pay {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    .btn {
      background: #07c160;
      width: 160px;
      height: 34px;
      border-radius: 6px;
      text-align: center;
      line-height: 34px;
      color: #fff;
      font-size: 16px;
    }
  }
  .pwd-input {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 275px;
    .van-password-input {
      width: 290px;
      .van-password-input__item {
        background: #dfdede;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 4px;
        flex: 1;
      }
    }
    ::v-deep .van-number-keyboard {
      .van-number-keyboard__body {
        padding-left: 1px;
        padding-top: 1px;
        .van-key__wrapper {
          padding-right: 1px;
          padding-bottom: 1px;
          div {
            height: 60px;
            border-radius: 0;
          }
        }
        .van-key__wrapper:nth-of-type(3n) {
          padding-right: 0;
        }
        .van-key__wrapper:nth-of-type(10) {
          div {
            background: #ededed;
            svg {
              display: none;
            }
          }
        }
        .van-key__wrapper:nth-of-type(12) {
          div {
            background: #ededed;
            // svg {
            //     display: none;
            // }
          }
        }
      }
    }
  }
  .inputPwdDur {
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #bfbfbf;
    font-size: 18px;
    .text {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .dotList {
      display: flex;
      div {
        width: 6px;
        height: 6px;
        background: #e5e5e5;
        border-radius: 50%;
        margin-left: 2px;
        margin-right: 2px;
      }
      .active {
        background: #7f7f7f;
      }
      .active1 {
        background: #b2b2b2;
      }
    }
  }
}
.shaoyishaoPop {
  padding: 20px 16px;
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(~"100% - 40px");
  background: #fff;
  color: rgba(0, 0, 0);
  .shaoyishaoPopHeader {
    position: relative;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
    .icon {
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 14px;
  }
  .text_main {
    font-size: 16px;
  }
  .text_sub {
    font-size: 14px;
  }
  .cash {
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.4);
    .title {
      display: flex;
      align-items: center;
    }
    .text_sub {
      margin-left: 30px;
    }
    span {
      color: #1f517c;
    }
  }
  .money {
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.4);
    .title {
      margin-bottom: 10px;
    }
    .content {
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
      .text {
        margin-left: 30px;
      }
    }
  }
  .cardList {
    .cardTitle {
      margin-bottom: 10px;
    }
    .card {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #f2f2f2;
      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        .name {
          flex: 1;
        }
      }
      .active {
        margin-right: 0;
      }
      .tips {
        margin-left: 30px;
        line-height: 16px;
      }
    }
    .avtiveCard {
      border-color: #fff;
    }
  }
  .addCard {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 16px;
    .arrow {
      margin-right: 0;
    }
  }
}
.buypayshow-title {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 400;
  position: relative;
  .buypayshow-title-icon {
    position: absolute;
    left: 0;
    top: 20px;
  }
}
.bank-item {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #e8e8e8;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .bank-icon {
    position: relative;
    width: 18px;
    margin-right: 20px;
    img {
      position: absolute;
      top: -10px;
      width: 16px;
      height: 16px;
    }
  }
  .bank {
    flex: 1;
    .bank-info {
      display: flex;
    }
    .bank-bottom {
      color: #ccc;
    }
    .bank-type {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}
.bank-new {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
  align-items: center;
  justify-content: space-between;
  .icon {
    width: 20px;
    margin-right: 18px;
  }
  .bank-new-text {
    flex: 1;
    text-align: left;
  }
}
.payDur {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: calc(~"50% - 50px");
  left: calc(~"50% - 50px");
  background: #4c4c4c;
  border-radius: 8px;
  z-index: 2024;
  img {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .text {
    color: #fff;
  }
  .dotList {
    display: flex;
    margin-top: 10px;
    div {
      width: 4px;
      height: 4px;
      background: #818181;
      border-radius: 50%;
      margin-left: 2px;
      margin-right: 2px;
    }
    .active {
      background: #fff;
    }
  }
}
.pwdPop {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  .header {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    position: relative;
    .close-icon {
      position: absolute;
      left: 0;
    }
  }
  .tip {
    text-align: center;
    margin-top: 6px;
  }
  .pwdValue {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    .label {
      margin-top: -6px;
      font-size: 16px;
    }
  }
  .pwd-input {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .van-password-input {
      width: 290px;
      .van-password-input__item {
        background: #dfdede;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 4px;
        flex: 1;
      }
    }
    ::v-deep .van-number-keyboard {
      .van-number-keyboard__body {
        padding-left: 1px;
        padding-top: 1px;
        .van-key__wrapper {
          padding-right: 1px;
          padding-bottom: 1px;
          div {
            height: 60px;
            border-radius: 0;
          }
        }
        .van-key__wrapper:nth-of-type(3n) {
          padding-right: 0;
        }
        .van-key__wrapper:nth-of-type(10) {
          div {
            background: #ededed;
            svg {
              display: none;
            }
          }
        }
        .van-key__wrapper:nth-of-type(12) {
          div {
            background: #ededed;
            // svg {
            //     display: none;
            // }
          }
        }
      }
    }
  }
}
.pwd-keyboard {
  height: 224px;
  z-index: 2030;
}
.finishPop {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  .icon {
    margin-top: 160px;
  }
  .tip {
    margin-top: 30px;
  }
  .payvalue {
    font-size: 38px;
    font-weight: bold;
    position: relative;
    padding-left: 14px;
    .label {
      font-size: 20px;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  .btn {
    position: absolute;
    bottom: 100px;
    width: 160px;
    border-radius: 8px;
    background: #eee;
    line-height: 40px;
    text-align: center;
  }
}
.fuwufei {
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 8px;
    color: #ccc;
    span:nth-of-type(2) {
      color: #000;
    }
}
.feilv {
    display: flex;
    justify-content: space-between;
    color: #ccc;
    span:nth-of-type(2) {
      color: #000;
    }
}
.finishTixianPop {
    position: relative;
    height: 100%;
    display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  .steps {
    width: 88%;
    .text {
        margin-left: 20px;
        color: #7f7f7f;
        font-size: 16px;
    }
    .defa {
        color: #000;
    }
  }
  .detail {
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin-left: 20px;
    margin-right: 20px;
    border-top: 1px solid #eee;
    padding-top:18px;
    .detail-item {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        
        font-size: 14px;
        div:nth-of-type(1) {
            color: #bbbbbb;
        }
    }
  }
    .btn {
        position: absolute;
        bottom: 100px;
        width: 160px;
        border-radius: 8px;
        background: #eee;
        line-height: 40px;
        text-align: center;
    }
}
.quanbu {
    margin-left: 20px;
}
</style>
